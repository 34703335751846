import { Box, Skeleton } from "@mui/material";

const TableSkeleton = () => {
    const skeletonArray = Array(4).fill(0);

    return (
        <>
            {skeletonArray.map((_, index) => (
                <Box
                    key={index}
                    sx={{
                        border: "1px solid gray",
                        display: "flex",
                        justifyContent: "space-between",
                        gap: "2rem",
                        padding: "1rem",
                        alignItems: "center",
                        borderRadius: "10px",
                        marginBottom: "1rem",
                    }}
                >
                    <Skeleton
                        variant="text"
                        sx={{ fontSize: "1rem", width: "100%", height: "3rem" }}
                    />
                    <Skeleton
                        variant="text"
                        sx={{ fontSize: "1rem", width: "100%", height: "3rem" }}
                    />
                    <Skeleton
                        variant="text"
                        sx={{ fontSize: "1rem", width: "100%", height: "3rem" }}
                    />
                    <Skeleton
                        variant="text"
                        sx={{ fontSize: "1rem", width: "100%", height: "3rem" }}
                    />
                    <Skeleton
                        variant="text"
                        width="100%"
                        height="3rem"
                        sx={{ borderRadius: "6px" }}
                    />
                    <Box sx={{ gap: "0.7rem", width: "100%", display: "flex" }}>
                        <Skeleton
                            sx={{ borderRadius: "10px" }}
                            variant="rounded"
                            width="100%"
                            height="2.5rem"
                        />
                        <Skeleton
                            sx={{ borderRadius: "10px" }}
                            variant="rounded"
                            width="100%"
                            height="2.5rem"
                        />
                        <Skeleton
                            sx={{ borderRadius: "10px" }}
                            variant="rounded"
                            width="100%"
                            height="2.5rem"
                        />
                    </Box>
                </Box>
            ))}
        </>
    );
};

export default TableSkeleton;
