import { ItemCancellationReasons } from "../models/orders/order-cancelation/ICancellationReasons";

export const getReasonSpecification = (
    id: string,
    cancellationReasons: ItemCancellationReasons[],
) => {
    const reason = cancellationReasons.find((item) => item.id === id);
    if (!reason) return "";

    if (reason.code === "ABSDSO") {
        return "El OR-RF no se presentó a la actividad o llegó tarde, antes de cancelar debe tener comunicación con Fronteras, no se puede ejecutar por situaciones atribuibles al OR o RF.";
    }
    return reason.description;
};
