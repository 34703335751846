import {
    AnimatePresence,
    Box,
    motion,
    Pagination,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@enerbit/base";
import moment from "moment";
import { useEffect, useState } from "react";
import { EEntity } from "../../enums/EServiceOrder";
import { useServiceOrders } from "../../store/ServiceOrders";
import { EmptyData } from "../EmptyData";
import TableSkeleton from "../Skeleton/TableSkeleton";
import { StyledChip } from "../style/StyledChip";
import { ButtonServiceOrderItem } from "./ButtonServiceOrderItem";

interface TableServicesOrdersPageProps {
    entity: EEntity;
    leadId?: string | undefined | null;
    measurementPointId: string;
}

const entityDictionary: Record<EEntity, string> = {
    MP: "cliente",
    LD: "lead",
};

const TableServicesOrdersPage: React.FC<TableServicesOrdersPageProps> = ({
    entity,
    leadId,
    measurementPointId,
}) => {
    const { servicesOrders } = useServiceOrders();

    const [pagePagination, setPagePagination] = useState<number>(0);

    const handlePageChange = (
        _event: React.ChangeEvent<unknown>,
        value: number,
    ) => {
        setPagePagination(value - 1);
    };

    const { getServicesOrders } = useServiceOrders();

    const [isLoadingServiceOrder, setIsLoadingServiceOrder] = useState(false);

    const getOSInitial = async () => {
        const id = leadId ? leadId : measurementPointId;
        setIsLoadingServiceOrder(true);
        await getServicesOrders({
            id: id,
            entity,
            dateRange: {
                startDate: "",
                endDate: "",
            },
            page: pagePagination,
        });
        setIsLoadingServiceOrder(false);
    };

    useEffect(() => {
        getOSInitial();
    }, [pagePagination]);

    if (isLoadingServiceOrder) {
        return <TableSkeleton />;
    }

    if (
        !servicesOrders ||
        !servicesOrders[entity] ||
        servicesOrders[entity]?.items?.length === 0
    ) {
        return (
            <EmptyData
                message={`No se encontraron órdenes para este ${entityDictionary[entity]}.`}
            />
        );
    }

    return (
        <>
            <TableContainer component={Paper} sx={{ boxShadow: "none" }}>
                <Table
                    className="Table-services-orders"
                    aria-label="simple table"
                >
                    <TableHead>
                        <TableRow>
                            <TableCell
                                align="left"
                                className="Table-th-items-services-orders"
                            >
                                ID orden
                            </TableCell>
                            <TableCell
                                align="left"
                                className="Table-th-items-services-orders"
                            >
                                Tipo de orden
                            </TableCell>
                            <TableCell
                                align="left"
                                className="Table-th-items-services-orders"
                            >
                                Fecha de registro
                            </TableCell>
                            <TableCell
                                align="left"
                                className="Table-th-items-services-orders"
                            >
                                Fecha de ejecución
                            </TableCell>
                            <TableCell
                                align="left"
                                className="Table-th-items-services-orders"
                            >
                                Estado
                            </TableCell>
                            <TableCell className="Table-th-items-services-orders">
                                Acciones
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow
                            sx={{
                                backgroundColor: "transparent",
                                "&:last-child td, &:last-child th": {
                                    border: 0,
                                    backgroundColor: "transparent !important",
                                },
                                height: "1.8rem",
                            }}
                        />
                        <AnimatePresence>
                            {servicesOrders &&
                                servicesOrders[entity]?.items?.map(
                                    (row, index) => {
                                        return (
                                            <TableRow
                                                component={motion.tr}
                                                layout
                                                initial={{ opacity: 0, x: -10 }}
                                                animate={{ opacity: 1, x: 0 }}
                                                exit={{ opacity: 0, x: -10 }}
                                                transition={{
                                                    delay: index * 0.25,
                                                }}
                                                key={index}
                                                sx={{
                                                    "&:last-child td, &:last-child th":
                                                        { border: 0 },
                                                }}
                                            >
                                                <TableCell
                                                    sx={{
                                                        borderTopLeftRadius:
                                                            "8px",
                                                        borderBottomLeftRadius:
                                                            "8px",
                                                    }}
                                                    align="left"
                                                >
                                                    {" "}
                                                    {row.id}
                                                </TableCell>
                                                <TableCell align="left">
                                                    {" "}
                                                    {row.order_type.name}{" "}
                                                </TableCell>
                                                <TableCell align="left">
                                                    {" "}
                                                    {moment(
                                                        row.created_at,
                                                    ).format("YYYY-MM-DD")}
                                                </TableCell>
                                                <TableCell align="left">
                                                    {" "}
                                                    {moment(
                                                        row.planned_date_begin,
                                                    ).format("YYYY-MM-DD")}
                                                </TableCell>
                                                <TableCell
                                                    align="left"
                                                    sx={{
                                                        padding:
                                                            "0 4rem 0 0!important",
                                                        minWidth: "12rem",
                                                    }}
                                                >
                                                    {row.status === "done" && (
                                                        <StyledChip
                                                            label="Completada"
                                                            sx={{
                                                                backgroundColor:
                                                                    "success.100",
                                                                color: "success.main",
                                                            }}
                                                        />
                                                    )}
                                                    {row.status ===
                                                        "pending" && (
                                                        <StyledChip
                                                            label="Pendiente"
                                                            sx={{
                                                                backgroundColor:
                                                                    "warning.200",
                                                                color: "warning.main",
                                                            }}
                                                        />
                                                    )}
                                                    {row.status ===
                                                        "cancel" && (
                                                        <StyledChip
                                                            label="Cancelada"
                                                            sx={{
                                                                backgroundColor:
                                                                    "error.100",
                                                                color: "error.main",
                                                            }}
                                                        />
                                                    )}
                                                    {row.status ===
                                                        "partial_done" && (
                                                        <StyledChip
                                                            label="Completada parcial"
                                                            sx={{
                                                                backgroundColor:
                                                                    "info.100",
                                                                color: "info.main",
                                                                border: "2px dashed",
                                                                borderColor:
                                                                    "info.main",
                                                            }}
                                                        />
                                                    )}
                                                    {row.status ===
                                                        "pending_assignment" && (
                                                        <StyledChip
                                                            label="En preparación"
                                                            sx={{
                                                                backgroundColor:
                                                                    "neutral.200",
                                                                color: "neutral.main",
                                                                border: "2px dashed",
                                                                borderColor:
                                                                    "neutral.main",
                                                            }}
                                                        />
                                                    )}
                                                </TableCell>

                                                <TableCell align="center">
                                                    <ButtonServiceOrderItem
                                                        orderItem={row}
                                                        entity={entity}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        );
                                    },
                                )}
                        </AnimatePresence>
                    </TableBody>
                </Table>
            </TableContainer>
            <Box className="Pagination-services-orders" sx={{ mt: "1.5rem" }}>
                <Pagination
                    count={servicesOrders[entity]?.pages}
                    shape="rounded"
                    page={pagePagination + 1}
                    onChange={handlePageChange}
                    sx={{ margin: "0.3rem 0 1rem 0" }}
                />
            </Box>
        </>
    );
};

export default TableServicesOrdersPage;
