import { ThemeConfig, initEnviroment } from "@enerbit/base";
import "./assets/css/ServicesOrders.scss";
import ServiceOrdersContainer from "./pages/ServiceOrdersContainer";

initEnviroment({
    baseUrl: process.env.REACT_APP_BASE_URL,
});

export default function Root({
    measurementPointId,
}: { measurementPointId: string }) {
    return (
        <ThemeConfig>
            <ServiceOrdersContainer measurementPointId={measurementPointId} />
        </ThemeConfig>
    );
}
