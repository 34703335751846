import { api, EnerbitPagination } from "@enerbit/base";
import { enqueueSnackbar } from "notistack";
import { create } from "zustand";
import { Leads } from "../models/assignees/ILeads";

export interface LeadsStore {
    leads: Leads | null;
    getLeads: (measurementPointId: string) => Promise<Leads[]>;
}

const initialState = {
    leads: null,
};

export const useLeads = create<LeadsStore>(() => ({
    ...initialState,
    getLeads: async (measurementPointId: string) => {
        try {
            const { data } = await api.get<EnerbitPagination<Leads>>(
                `/assignees/leads?measurement_point_id=${measurementPointId}&page=1&size=50`,
            );
            return data.items;
        } catch (error) {
            enqueueSnackbar("Ocurrió un error al cargar los leads.", {
                variant: "error",
            });
            throw error;
        }
    },
}));
