export enum EEntity {
    Lead = "LD",
    Client = "MP",
}

export enum EOrderStatus {
    "En preparación" = "pending_assignment",
    Pendiente = "pending",
    "Parcial Completada" = "partial_done",
    Completada = "done",
    Cancelada = "cancel",
}
