import { Box, NotiProvider } from "@enerbit/base";
import { useEffect, useState } from "react";
import ServiceOrders from "../components/ServiceOrders";
import TableSkeleton from "../components/Skeleton/TableSkeleton";
import { EEntity } from "../enums/EServiceOrder";
import { useLeads } from "../store/Leads";

export const ServiceOrdersContainer = ({
    measurementPointId,
}: { measurementPointId: string }) => {
    const { getLeads } = useLeads();

    const [leadId, setLeadId] = useState<string | undefined>();
    const [isLoadingLeads, setIsLoadingLeads] = useState(true);

    useEffect(() => {
        if (measurementPointId) {
            getLeads(measurementPointId).then((leads) => {
                if (leads) {
                    const lead = leads.find(
                        (lead) =>
                            lead.measurement_point_id === measurementPointId,
                    )?.id;
                    setLeadId(lead);
                }
                setIsLoadingLeads(false);
            });
        }
    }, []);

    return (
        <>
            <NotiProvider>
                <>
                    <Box>
                        {isLoadingLeads ? (
                            <Box sx={{ margin: "6rem 0" }}>
                                <TableSkeleton />
                            </Box>
                        ) : (
                            <>
                                <ServiceOrders
                                    entity={EEntity.Client}
                                    measurementPointId={measurementPointId}
                                />

                                <ServiceOrders
                                    entity={EEntity.Lead}
                                    leadId={leadId}
                                    measurementPointId={measurementPointId}
                                />
                            </>
                        )}
                    </Box>
                </>
            </NotiProvider>
        </>
    );
};
export default ServiceOrdersContainer;
