import { enerbitColors } from "@enerbit/base";
import {
    Box,
    Button,
    Grid,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    SelectChangeEvent,
    Skeleton,
    TextField,
    Typography,
} from "@mui/material";
import { FormikProps } from "formik";
import { ItemCancellationReasons } from "../../models";
import { useServiceOrders } from "../../store/ServiceOrders";
import { getReasonSpecification } from "../../utils/ReasonSpecification";
import { ICancelServiceOrderForm } from "./ServiceOrderCancellationModalContainer";

interface CancelServiceOrderFormProps {
    formik: FormikProps<ICancelServiceOrderForm>;
}

const renderReasonDescription = (
    reasonId: string,
    cancellationReasons: ItemCancellationReasons[],
) => {
    if (!reasonId) return;
    return (
        <Grid item xs={12}>
            <Box
                sx={{
                    margin: "0.3rem 0 0.9rem 0",
                    padding: "12px 16px 12px 16px",
                    border: `1px solid #53358E`,
                    borderRadius: "16px",
                    backgroundColor: "#EEEBF4",
                    color: enerbitColors.primary.main,
                }}
            >
                <Typography sx={{ fontWeight: 700 }}>Especificación</Typography>
                <Typography>
                    {getReasonSpecification(reasonId, cancellationReasons)}
                </Typography>
            </Box>
        </Grid>
    );
};

const CancelServiceOrderForm = ({ formik }: CancelServiceOrderFormProps) => {
    const {
        cancellationReasons,
        cancellationResponsibilities,
        loadingCancellationReasons,
    } = useServiceOrders();

    const { setFieldValue } = formik;

    const handleResponsibilityChange = (event: SelectChangeEvent<string>) => {
        setFieldValue("cancellationResponsibility", event.target.value ?? "");
        setFieldValue("cancellation_reason_id", "");
    };

    return (
        <form onSubmit={formik.handleSubmit}>
            <Grid
                direction="column"
                justifyContent="center"
                alignItems="stretch"
                sx={{
                    flexGrow: 1,
                    height: "100%",
                    marginTop: "0.5rem",
                    mb: "1rem",
                }}
                container
            >
                <Grid item xs={12} md={3} lg={3} sx={{ mb: "1rem" }}>
                    <Box
                        sx={{
                            mb: "0.7rem",
                            color: enerbitColors.neutral[600],
                            fontWeight: "bold",
                            fontSize: "1rem",
                        }}
                    >
                        Responsable
                    </Box>
                    <Box className="TextField-without-border-radius Text-select-field">
                        {loadingCancellationReasons ? (
                            <Skeleton
                                variant="text"
                                sx={{ fontSize: "1rem" }}
                                height={60}
                            />
                        ) : (
                            <Select
                                fullWidth
                                value={formik.values.cancellationResponsibility}
                                onChange={handleResponsibilityChange}
                                displayEmpty
                                renderValue={(selected) => {
                                    if (!selected) {
                                        return (
                                            <span style={{ color: "gray" }}>
                                                Elige el responsable
                                            </span>
                                        );
                                    }
                                    return selected;
                                }}
                                input={
                                    <OutlinedInput
                                        sx={{
                                            "& .MuiOutlinedInput-input": {
                                                color: formik.values
                                                    .cancellationResponsibility
                                                    ? "black"
                                                    : "gray",
                                            },
                                            "& .MuiOutlinedInput-notchedOutline":
                                                {
                                                    borderColor:
                                                        formik.touched
                                                            .cancellationResponsibility &&
                                                        formik.errors
                                                            .cancellationResponsibility
                                                            ? "red"
                                                            : `${enerbitColors.primary[300]}`,
                                                    borderRadius: "0.8rem",
                                                },
                                            "&:hover .MuiOutlinedInput-notchedOutline":
                                                {
                                                    borderColor:
                                                        formik.touched
                                                            .cancellationResponsibility &&
                                                        formik.errors
                                                            .cancellationResponsibility
                                                            ? "red"
                                                            : `${enerbitColors.primary[300]}`,
                                                },
                                            "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                                {
                                                    borderColor:
                                                        formik.touched
                                                            .cancellationResponsibility &&
                                                        formik.errors
                                                            .cancellationResponsibility
                                                            ? "red"
                                                            : `${enerbitColors.primary[300]}`,
                                                },
                                        }}
                                    />
                                }
                            >
                                {cancellationResponsibilities.map(
                                    (responsibility) => (
                                        <MenuItem
                                            key={responsibility}
                                            value={responsibility}
                                        >
                                            {responsibility}
                                        </MenuItem>
                                    ),
                                )}
                            </Select>
                        )}
                        {formik.errors.cancellationResponsibility &&
                            formik.touched.cancellationResponsibility && (
                                <div style={{ color: "red" }}>
                                    {String(
                                        formik.errors
                                            .cancellationResponsibility,
                                    )}
                                </div>
                            )}
                    </Box>
                </Grid>
                <Grid item xs={12} md={3} lg={3} sx={{ mb: "1rem" }}>
                    <Box
                        sx={{
                            mb: "0.7rem",
                            color: enerbitColors.neutral[600],
                            fontWeight: "bold",
                            fontSize: "1rem",
                        }}
                    >
                        Motivo de cancelación
                    </Box>
                    <Select
                        fullWidth
                        value={formik.values.cancellation_reason_id}
                        onChange={(event: SelectChangeEvent<string | null>) =>
                            formik.setFieldValue(
                                "cancellation_reason_id",
                                event.target.value ?? "",
                            )
                        }
                        displayEmpty
                        renderValue={(selected) => {
                            if (!selected) {
                                return (
                                    <span style={{ color: "gray" }}>
                                        Selecciona el motivo
                                    </span>
                                );
                            }
                            const reason = cancellationReasons.find(
                                (reason) => reason.id === selected,
                            );
                            return reason ? reason.name : selected;
                        }}
                        input={
                            <OutlinedInput
                                sx={{
                                    "& .MuiOutlinedInput-notchedOutline": {
                                        borderColor:
                                            formik.touched
                                                .cancellation_reason_id &&
                                            formik.errors.cancellation_reason_id
                                                ? "red"
                                                : `${enerbitColors.primary[300]}`,
                                        borderRadius: "0.8rem",
                                    },
                                    "&:hover .MuiOutlinedInput-notchedOutline":
                                        {
                                            borderColor:
                                                formik.touched
                                                    .cancellation_reason_id &&
                                                formik.errors
                                                    .cancellation_reason_id
                                                    ? "red"
                                                    : `${enerbitColors.primary[300]}`,
                                        },
                                    "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                        {
                                            borderColor:
                                                formik.touched
                                                    .cancellation_reason_id &&
                                                formik.errors
                                                    .cancellation_reason_id
                                                    ? "red"
                                                    : `${enerbitColors.primary[300]}`,
                                        },
                                }}
                            />
                        }
                    >
                        {cancellationReasons
                            .filter(
                                (item) =>
                                    item.responsible ===
                                    formik.values.cancellationResponsibility,
                            )
                            .map(({ name, id }, index) => (
                                <MenuItem key={index} value={id}>
                                    {name}
                                </MenuItem>
                            ))}
                    </Select>
                    {formik.errors.cancellation_reason_id &&
                        formik.touched.cancellation_reason_id && (
                            <div style={{ color: "red" }}>
                                {String(formik.errors.cancellation_reason_id)}
                            </div>
                        )}
                </Grid>
                {renderReasonDescription(
                    formik.values.cancellation_reason_id,
                    cancellationReasons,
                )}
                <Grid item xs={12} sx={{ mb: "1rem" }}>
                    <InputLabel
                        sx={{
                            "& .MuiFormLabel-asterisk": {
                                display: "none",
                            },
                            mb: "0.7rem",
                            color: enerbitColors.neutral[600],
                            fontWeight: "bold",
                            fontSize: "1rem",
                        }}
                        required
                    >
                        Observaciones
                    </InputLabel>
                    <TextField
                        fullWidth
                        id="observations"
                        type="text"
                        sx={{ "& fieldset": { borderRadius: "14px" } }}
                        variant="outlined"
                        rows={4}
                        multiline
                        value={formik.values.observations}
                        onChange={formik.handleChange}
                        error={
                            formik.touched.observations &&
                            Boolean(formik.errors.observations)
                        }
                        helperText={
                            formik.touched.observations &&
                            formik.errors.observations
                                ? String(formik.errors.observations)
                                : undefined
                        }
                    />
                </Grid>
                <Grid item xs={12} md={2} lg={2}>
                    <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        sx={{
                            mt: "10px !important",
                            pt: "12px",
                            height: "100%",
                        }}
                    >
                        <Button
                            fullWidth
                            variant="contained"
                            color="secondary"
                            type="submit"
                        >
                            Cancelar orden de servicios
                        </Button>
                    </Box>
                </Grid>
            </Grid>
        </form>
    );
};

export default CancelServiceOrderForm;
