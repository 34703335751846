import { decodedJwt } from "@enerbit/base";
import { useFormik } from "formik";
import { useState } from "react";
import * as yup from "yup";
import { EEntity } from "../../enums/EServiceOrder";
import { ICancelServiceOrder, ItemGetOrders } from "../../models";
import { useServiceOrders } from "../../store/ServiceOrders";
import CancelServiceOrderConfirmationDialog from "./CancelServiceOrderConfirmationDialog";
import CancelServiceOrderForm from "./CancelServiceOrderForm";

export interface ICancelServiceOrderForm extends ICancelServiceOrder {
    cancellationResponsibility: string;
}
export type ServiceOrderCancellationModalContainerProps = {
    orderItem: ItemGetOrders;
    entity: EEntity;
    handleClose?: (
        event: {},
        reason: "backdropClick" | "escapeKeyDown" | "submit",
    ) => void;
};
export const ServiceOrderCancellationModalContainer = ({
    handleClose,
    orderItem,
    entity,
}: ServiceOrderCancellationModalContainerProps) => {
    const { cancelServiceOrder } = useServiceOrders();

    const [confirmOpen, setConfirmOpen] = useState(false);

    const initialValues: ICancelServiceOrderForm = {
        cancellationResponsibility: "",
        order_id: orderItem.id,
        cancel_by: decodedJwt()?.sub || "",
        cancellation_reason_id: "",
        form_data: {
            geolocalization: null,
        },
        observations: "",
        picture_url: null,
    };

    const schemaCancelServiceOrder = yup.object().shape({
        cancellationResponsibility: yup
            .string()
            .required("Responsable es requerido"),
        cancellation_reason_id: yup
            .string()
            .required("Motivo de cancelación es requerido"),
        observations: yup.string().required("Observaciones son requeridas"),
    });

    const onSubmit = () => {
        setConfirmOpen(true);
    };

    const formik = useFormik({
        initialValues,
        validationSchema: schemaCancelServiceOrder,
        onSubmit,
    });

    const handleConfirmSubmit = () => {
        const { cancellationResponsibility, ...formData } = formik.values;
        cancelServiceOrder(formData, entity);
        setConfirmOpen(false);
        if (handleClose) {
            handleClose({}, "submit");
        }
    };
    return (
        <>
            <CancelServiceOrderForm formik={formik} />
            <CancelServiceOrderConfirmationDialog
                open={confirmOpen}
                onClose={() => setConfirmOpen(false)}
                onConfirm={handleConfirmSubmit}
            />
        </>
    );
};
