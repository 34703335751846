import {
    CloseIcon,
    ContentPasteSearchOutlinedIcon,
    RemoveRedEyeOutlinedIcon,
    Stack,
    Tooltip,
} from "@enerbit/base";
import { useState } from "react";
import { EEntity } from "../../enums/EServiceOrder";
import { ItemGetOrders } from "../../models";
import { enabledCertificates } from "../../utils/EnabledCertificate";
import ServiceOrderCancellationModal from "../Modal/ServiceOrderCancellationModal";
import { StyledIconButton } from "../style/StyledIconButton";

interface ButtonServiceOrderItemProps {
    orderItem: ItemGetOrders;
    entity: EEntity;
}

export type ActionType = "Create" | "Consult" | "Cancel" | "Certifications";

export const ButtonServiceOrderItem: React.FC<ButtonServiceOrderItemProps> = ({
    orderItem,
    entity,
}) => {
    const [openModalCancelServiceOrders, setOpenModalCancelServiceOrders] =
        useState(false);
    const { order_type, status } = orderItem;

    const handleAction = (action: ActionType) => {
        const actionsToRoutes: Record<ActionType, string> = {
            Consult: `/#/orders/${orderItem.id}`,
            Cancel: `/#/orders/cancel/${orderItem.id}`,
            Certifications: `/#/orders/certifications/${orderItem.id}`,
            Create: "",
        };

        const url = actionsToRoutes[action];
        window.location.href = url; // Redirige a la URL externa con el ID
    };

    return (
        <>
            <Stack
                direction="row"
                spacing={1}
                sx={{
                    width: "40%",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                {(status === "pending" || status === "pending_assignment") && (
                    <>
                        <Tooltip title="Cancelar orden de servicio">
                            <StyledIconButton
                                sx={{ width: 36, height: 36 }}
                                color="error"
                                onClick={() =>
                                    setOpenModalCancelServiceOrders(true)
                                }
                            >
                                <CloseIcon />
                            </StyledIconButton>
                        </Tooltip>

                        <ServiceOrderCancellationModal
                            orderItem={orderItem}
                            entity={entity}
                            open={openModalCancelServiceOrders}
                            handleClose={() => {
                                setOpenModalCancelServiceOrders(false);
                            }}
                        />
                    </>
                )}
                <Tooltip title="Detalle de la orden">
                    <StyledIconButton
                        color="primary"
                        onClick={() => handleAction("Consult")}
                        sx={{ width: 36, height: 36 }}
                    >
                        <RemoveRedEyeOutlinedIcon sx={{ fontSize: "1rem" }} />
                    </StyledIconButton>
                </Tooltip>
                {(status === "done" || status === "partial_done") &&
                    enabledCertificates(order_type.code) && (
                        <Tooltip title="Ver acta">
                            <StyledIconButton
                                sx={{ width: 36, height: 36 }}
                                color="info"
                                onClick={() => handleAction("Certifications")}
                            >
                                <ContentPasteSearchOutlinedIcon />
                            </StyledIconButton>
                        </Tooltip>
                    )}
            </Stack>
        </>
    );
};
